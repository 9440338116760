import * as React from "react"
import { Link, graphql } from "gatsby"

import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const posts = data.allMarkdownRemark.nodes
  return (
    <div className="global-wrapper">
      <header className="global-header">
      <Link className="header-link-home" to="/">
         Is This A Cyber Attack?
      </Link>
      
       <h1 className="main-heading">
        Incidents
      </h1>
      </header>
      <main>
      <Seo title="All posts" />
     
      <ol id="index" style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.Name
          const verdict = post.frontmatter.Attack || 'No'
          const color = verdict === "No" ? "color-no": "color-yes"

          return (
            <li key={post.fields.slug}>
             Was <Link className="incident" to={`/${post.fields.slug}`} itemProp="url"><span itemProp="headline">{title}</span></Link> A Cyber Attack? <span className={color}>{verdict}</span>
            </li>
          )
        })}
      </ol>
      </main>
    </div>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        metaTitle
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___Date], order: DESC }, filter: {frontmatter: {Name: {ne: ""}}}) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          Attack
          Name
          Date
          Group
        }
      }
    }
  }
`
